@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../../mixins';

.CrossLinks {
  @include mixins.falcon-text-component-max-width;

  &__header {
    margin-bottom: tokens.bpk-spacing-sm();
  }

  &__ul {
    margin-top: tokens.bpk-spacing-md();
    padding: 0;
    flex-wrap: wrap;

    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: 0;
    }
  }
}
