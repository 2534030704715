/* stylelint-disable selector-class-pattern */

@use '@skyscanner/backpack-web/unstable__bpk-mixins/icons';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/margins';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.CrossLink {
  font-size: tokens.$bpk-font-size-lg;
  list-style: none;
  box-shadow: 0 tokens.$bpk-one-pixel-rem 0 #e6e4eb;

  &__icon {
    fill: tokens.$bpk-color-sky-gray;

    @include margins.bpk-margin-trailing(tokens.bpk-spacing-base());
  }

  &__text {
    flex-grow: 1;
  }

  a.linkListItem {
    display: flex;
    padding: tokens.bpk-spacing-base() 0;
    flex-direction: row;
    align-items: center;
    color: tokens.$bpk-font-color-base;
    font-weight: tokens.$bpk-font-weight-bold;

    &:hover:not(:active, :disabled) {
      color: tokens.$bpk-font-color-base;
      text-decoration: none;
    }
  }

  &__chevron {
    fill: tokens.$bpk-font-color-base;

    @include margins.bpk-margin-leading(tokens.bpk-spacing-md());
    @include icons.bpk-icon--rtl-support;
  }
}
